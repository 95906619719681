// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import AnchorNavigation from '@cloudscape-design/components/anchor-navigation';
import Box from '@cloudscape-design/components/box';

function OnThisPageNavigation({
  displayDiagnosis,
  displayStage,
  displayRegimen
}: {
  displayDiagnosis: boolean;
  displayStage: boolean;
  displayRegimen: boolean;
}) {
  const anchors = [];

  if (displayDiagnosis) {
    anchors.push({
      text: 'Diagnosis Details',
      href: '#diagnosis-details',
      level: 1
    });
  }

  if (displayStage) {
    anchors.push({
      text: 'Stage Details',
      href: '#stage-details',
      level: 1
    });
  }

  if (displayRegimen) {
    anchors.push({
      text: 'Regimen Summary',
      href: '#regimen-summary',
      level: 1
    });
  }

  const anchorNavigation = (
    <AnchorNavigation
      anchors={[
        {
          text: 'Patient Summary',
          href: '#top',
          level: 1
        },
        ...anchors
      ]}
      ariaLabelledby="navigation-header"
      onFollow={async (e) => {
        e.preventDefault();
        let anchor = document.getElementById(e.detail.href.split('#')[1]);
        await setTimeout(() => {
          anchor?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }}
    />
  );

  return (
    <div className="on-this-page--side" data-testid="on-this-page">
      <Box variant="h2" margin={{ bottom: 'xxs' }}>
        <span id="navigation-header">Pathway Index</span>
      </Box>
      {anchorNavigation}
    </div>
  );
}

export { OnThisPageNavigation };
