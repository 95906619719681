/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import React, { Dispatch, SetStateAction, useState, useRef } from 'react';
import {
  Button,
  Container,
  Header,
  Table,
  Box,
  SpaceBetween,
  ColumnLayout,
  Tabs,
  Link,
  StatusIndicator
} from '@cloudscape-design/components';
import { appSyncDefaultApiResponse } from '../../common/backend/ApiResponse';
import ApiResponse, { defaultApiResponse } from '../../common/backend/ApiResponse';
import FhirBackend from '../../common/backend/FhirBackend';
import './ExploreResults.scss';
import { ApolloQueryResult } from '@apollo/client';
import {
  PathwayPatient,
  PathwayDiagnosis,
  PathwayDiagnosisInfo,
  PathwayRegimen,
  PathwayMedicationAdministration,
  PathwayCycle,
  PathwayMedicationRequest,
  PathwayDiagnosisStage
} from './Types';
import { Results } from '../common/Results';
import { CustomBreadCrumb } from '../common/CustomBreadCrumb';
import ValueWithLabel from '../ValueWithLabel';
import Status from '../Status';
import { OnThisPageNavigation } from './OnThisPageNavigation';

export const ExploreResults: React.FC<{
  apiResponse: ApolloQueryResult<any>;
  setApiResponse: Dispatch<SetStateAction<ApolloQueryResult<any>>>;
  fhirBackend: FhirBackend;
}> = ({ apiResponse, setApiResponse, fhirBackend }) => {
  const [fhirApiResponse, setFhirApiResponse] = useState<ApiResponse>(defaultApiResponse);
  const [isReading, setIsReading] = React.useState(false);

  async function readResource(resourceType: string, resourceId: string): Promise<any> {
    setIsReading(true);
    const response = await fhirBackend.read(resourceType, resourceId);
    setIsReading(false);
    return response;
  }

  const {
    data: {
      getPathwayByNHI: { patient, diagnoses, linkedNhis }
    }
  }: {
    data: {
      getPathwayByNHI: {
        patient: PathwayPatient;
        diagnoses: PathwayDiagnosisInfo[];
        linkedNhis: string[];
      };
    };
  } = apiResponse;

  const PatientInfo = ({ patient, linkedNhis }: { patient: PathwayPatient; linkedNhis: string[] }) => (
    <Container
      header={
        <Box>
          <Link
            fontSize="heading-l"
            onFollow={async (e) => {
              const result = await readResource('Patient', patient.id);
              setFhirApiResponse(result);
              let fhir = document.getElementById('fhir');
              e.preventDefault();
              setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
            }}
          >
            Patient Information
          </Link>
          {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
        </Box>
      }
    >
      <SpaceBetween size="m" direction="vertical">
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Given Name">{patient.name.given}</ValueWithLabel>
            <ValueWithLabel label="Family Name">{patient.name.family}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Age">{patient.age}</ValueWithLabel>
            <ValueWithLabel label="Birth Date">{patient.birthDate}</ValueWithLabel>
          </SpaceBetween>

          <ValueWithLabel label="Gender">{patient.gender}</ValueWithLabel>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="NHI">{patient.nhi}</ValueWithLabel>
            {linkedNhis.length ? (
              <ValueWithLabel label="Linked NHI Numbers">{linkedNhis.join(', ')}</ValueWithLabel>
            ) : null}
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const DiagnosisDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => (
    <Container header={<Header variant="h2">Diagnosis Details</Header>}>
      <SpaceBetween size="m" direction="vertical">
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween direction="vertical" size="m">
            <Box>
              <Link
                fontSize="heading-xs"
                onFollow={async (e) => {
                  const result = await readResource('Condition', diagnosis.id);
                  setFhirApiResponse(result);
                  let fhir = document.getElementById('fhir');
                  e.preventDefault();
                  setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                }}
              >
                <ValueWithLabel label="Diagnosis">{diagnosis.diagnosis}</ValueWithLabel>
              </Link>
              {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
            </Box>
            <ValueWithLabel label="Date of Diagnosis">{diagnosis.dateOfDiagnosis}</ValueWithLabel>
          </SpaceBetween>

          <Box>
            <Link
              fontSize="heading-xs"
              onFollow={async (e) => {
                const result = await readResource('Observation', diagnosis.evidenceId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              <ValueWithLabel label="Evidence">{diagnosis.evidence}</ValueWithLabel>
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Histology Date">{diagnosis.histologyDate}</ValueWithLabel>
            <ValueWithLabel label="Histology">{diagnosis.histology}</ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <ValueWithLabel label="Laterality">{diagnosis.laterality}</ValueWithLabel>
            <ValueWithLabel label="Body Site">{diagnosis.bodySite}</ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </SpaceBetween>
    </Container>
  );

  const StageDetails = ({ diagnosis }: { diagnosis: PathwayDiagnosis }) => {
    const stagesSummaryColumns = [
      {
        header: 'Stage Grouping',
        cell: (item: PathwayDiagnosisStage) => item.stageGrouping,
        id: 'stageGrouping'
      },
      {
        header: 'Stage',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.stageId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.stage}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'stage'
      },
      {
        header: 'Stage Date',
        cell: (item: PathwayDiagnosisStage) => item.stageDate,
        id: 'stageDate'
      },
      {
        header: 'T Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.tId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.t} {item.tDate}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'tScore'
      },
      {
        header: 'N Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.nId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.n} {item.nDate}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'nScore'
      },
      {
        header: 'M Score',
        cell: (item: PathwayDiagnosisStage) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('Observation', item.mId);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.m} {item.mDate}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'mScore'
      }
    ];

    return (
      <Container header={<Header variant="h2">Stage Summary</Header>}>
        <Table
          variant="borderless"
          wrapLines={true}
          empty="No stage assessments have been done"
          items={diagnosis.stages}
          columnDefinitions={stagesSummaryColumns}
        />
      </Container>
    );
  };

  const CycleInfo = ({ cycle }: { cycle: PathwayCycle }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationAdministration', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => item.start,
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => item.end,
        id: 'end'
      }
    ];

    const medicationRequestSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationRequest) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationRequest', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationRequest) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationRequest) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationRequest) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      }
    ];

    return (
      <Box padding={{ left: 'l', right: 'l' }}>
        <SpaceBetween direction="vertical" size="l">
          <Box>
            <ColumnLayout columns={1}>
              <SpaceBetween direction="vertical" size="m">
                <ColumnLayout columns={4}>
                  <Box>
                    <Link
                      fontSize="heading-xs"
                      onFollow={async (e) => {
                        const result = await readResource('CarePlan', cycle.id);
                        setFhirApiResponse(result);
                        let fhir = document.getElementById('fhir');
                        e.preventDefault();
                        setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                      }}
                    >
                      <ValueWithLabel label="Cycle Number">{cycle.number}</ValueWithLabel>
                    </Link>
                    {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                  </Box>
                  <ValueWithLabel label="Cycle Duration">{cycle.duration}</ValueWithLabel>
                  <ValueWithLabel label="Cycle Start Date">{cycle.start}</ValueWithLabel>
                  <ValueWithLabel label="Cycle End Date">{cycle.end}</ValueWithLabel>
                </ColumnLayout>
              </SpaceBetween>
              <br />
              <Table
                header={<Header variant="h3">Medication Administration</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No medication administrations"
                items={cycle.medicationAdministrations}
                columnDefinitions={medicationAdministrationSummaryColumns}
              />
              <Table
                header={<Header variant="h3">Medication Requests</Header>}
                variant="borderless"
                wrapLines={true}
                empty="No mediciation requests"
                items={cycle.medicationRequests}
                columnDefinitions={medicationRequestSummaryColumns}
              />
            </ColumnLayout>
          </Box>
        </SpaceBetween>
      </Box>
    );
  };

  const RegimenDetails = ({ regimen }: { regimen: PathwayRegimen }) => {
    const medicationAdministrationSummaryColumns = [
      {
        header: 'Name',
        cell: (item: PathwayMedicationAdministration) => (
          <Box>
            <Link
              onFollow={async (e) => {
                const result = await readResource('MedicationAdministration', item.id);
                setFhirApiResponse(result);
                let fhir = document.getElementById('fhir');
                e.preventDefault();
                setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
              }}
            >
              {item.name}
            </Link>
            {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
          </Box>
        ),
        id: 'name'
      },
      {
        header: 'Dose',
        cell: (item: PathwayMedicationAdministration) => item.dose,
        id: 'dose'
      },
      {
        header: 'Timing',
        cell: (item: PathwayMedicationAdministration) => item.timing,
        id: 'timing'
      },
      {
        header: 'Status',
        cell: (item: PathwayMedicationAdministration) => <Status>{item.status.toLocaleLowerCase()}</Status>,
        id: 'status'
      },
      {
        header: 'Start',
        cell: (item: PathwayMedicationAdministration) => item.start,
        id: 'start'
      },
      {
        header: 'End',
        cell: (item: PathwayMedicationAdministration) => item.end,
        id: 'end'
      }
    ];

    return (
      <>
        <Box padding={{ bottom: 'm', left: 'l', right: 'l' }}>
          <SpaceBetween size="xxl" direction="vertical">
            <SpaceBetween direction="vertical" size="m">
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween direction="vertical" size="m">
                  <Box>
                    <Link
                      fontSize="heading-xs"
                      onFollow={async (e) => {
                        const result = await readResource('CarePlan', regimen.id);
                        setFhirApiResponse(result);
                        let fhir = document.getElementById('fhir');
                        e.preventDefault();
                        setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                      }}
                    >
                      <ValueWithLabel label="Intent of Treatment">{regimen.intent}</ValueWithLabel>
                    </Link>
                    {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                  </Box>
                  <ValueWithLabel label="Status of Treatment">
                    <Status>{regimen.status.toLocaleLowerCase()}</Status>
                  </ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="m">
                  <Box>
                    <Link
                      fontSize="heading-xs"
                      onFollow={async (e) => {
                        const result = await readResource('Observation', regimen.ecogId);
                        setFhirApiResponse(result);
                        let fhir = document.getElementById('fhir');
                        e.preventDefault();
                        setTimeout(() => fhir?.scrollIntoView({ behavior: 'smooth' }), 100);
                      }}
                    >
                      <ValueWithLabel label="ECOG Status">{regimen.ecogStatus}</ValueWithLabel>
                    </Link>
                    {isReading ? <StatusIndicator type="loading"></StatusIndicator> : null}
                  </Box>
                  <ValueWithLabel label="ECOG Date">{regimen.ecogDate}</ValueWithLabel>
                </SpaceBetween>
                <SpaceBetween direction="vertical" size="m">
                  <ValueWithLabel label="Treatment Start Date">{regimen.start}</ValueWithLabel>
                  <ValueWithLabel label="Treatment End Date">{regimen.end}</ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </SpaceBetween>
            <Table
              variant="borderless"
              header={<Header variant="h2">Latest Treatment</Header>}
              wrapLines={true}
              items={regimen.lastTreatment}
              columnDefinitions={medicationAdministrationSummaryColumns}
            />
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="m">
            <br />
            <Header variant="h2">Cycle Information</Header>
            <Box padding={'m'} float="left">
              <ColumnLayout columns={6} variant="text-grid">
                <ValueWithLabel size="large" label="Total Cycles">
                  {regimen.cycleCount}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Active Cycles">
                  {regimen.activeCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Completed Cycles">
                  {regimen.completedCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Unknown Cycles">
                  {regimen.unknownCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="Revoked Cycles">
                  {regimen.revokedCycles}
                </ValueWithLabel>
                <ValueWithLabel size="large" label="On-hold Cycles">
                  {regimen.onHoldCycles}
                </ValueWithLabel>
              </ColumnLayout>
            </Box>
            <Tabs
              tabs={regimen.cycles.map((cycle: PathwayCycle, index: number) => ({
                label: `${cycle.name}`,
                id: `${index}`,
                content: <CycleInfo key={index} cycle={cycle} />
              }))}
            />
          </SpaceBetween>
        </Box>

        {regimen.futureCycles.length ? (
          <Container header={<Header variant="h2">Future Cycle Information</Header>}>
            {regimen.futureCycles.map((cycle: PathwayCycle, index: number) => (
              <CycleInfo key={index} cycle={cycle} />
            ))}
          </Container>
        ) : null}
      </>
    );
  };

  function exploreResults() {
    return (
      <SpaceBetween direction="vertical" size="m">
        <div id="top">
          <CustomBreadCrumb
            items={[{ text: `Patient Cancer Pathway`, href: '/explore' }]}
            header={
              <Header
                actions={
                  <Button
                    onClick={() => {
                      setApiResponse(appSyncDefaultApiResponse);
                    }}
                  >
                    Back
                  </Button>
                }
                counter={`(${patient.nhi})`}
              >
                Patient Cancer Pathway
              </Header>
            }
          />
        </div>
        <div className="product-page-content-grid">
          <aside aria-label="Side bar" className="product-page-aside">
            <div className="product-page-aside-sticky">
              <SpaceBetween size="xl">
                <div className="on-this-page--side">
                  <OnThisPageNavigation
                    displayDiagnosis={diagnoses.some((diagnosis) => diagnosis.diagnosis)}
                    displayRegimen={diagnoses.some((diagnosis) => diagnosis.regimens?.length > 0)}
                    displayStage={diagnoses.some((diagnosis) => diagnosis.diagnosis.stages?.length > 0)}
                  />
                </div>
              </SpaceBetween>
            </div>
          </aside>
          <main className="product-page-content">
            <PatientInfo patient={patient} linkedNhis={linkedNhis} />
            <Tabs
              tabs={
                diagnoses.map((diagnosis: PathwayDiagnosisInfo, index: number) => ({
                  label: `${diagnosis.diagnosis.diagnosis}`,
                  id: `${index}`,
                  content: (
                    <SpaceBetween direction="vertical" size="m">
                      <div id="diagnosis-details">
                        <DiagnosisDetails key={index} diagnosis={diagnosis.diagnosis} />
                      </div>
                      {diagnosis.diagnosis.stages?.length ? (
                        <div id="stage-details">
                          <StageDetails key={index} diagnosis={diagnosis.diagnosis} />
                        </div>
                      ) : null}
                      <div id="regimen-summary">
                        <Container header={<Header variant="h2">Regimen Summary</Header>}>
                          <Tabs
                            tabs={diagnosis.regimens.map((regimen, regimenIndex) => ({
                              label: `${regimen.name}`,
                              id: `${regimenIndex}`,
                              content: <RegimenDetails key={regimenIndex} regimen={regimen} />
                            }))}
                          />
                        </Container>
                      </div>
                    </SpaceBetween>
                  )
                })) ?? []
              }
            />
            <div id="fhir">
              {fhirApiResponse.statusCode ? (
                <Container>
                  <Results
                    apiResponse={fhirApiResponse}
                    setApiResponse={setFhirApiResponse}
                    button={
                      <Button
                        onClick={async (e) => {
                          let top = document.getElementById('top');
                          e.preventDefault();
                          await setTimeout(() => {
                            top?.scrollIntoView({ behavior: 'smooth' });
                          }, 100);
                        }}
                      >
                        Back
                      </Button>
                    }
                  />
                </Container>
              ) : null}
            </div>
          </main>
        </div>
      </SpaceBetween>
    );
  }

  function results() {
    return exploreResults();
  }

  return results();
};
