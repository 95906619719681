/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { Input } from '@cloudscape-design/components';
import React, { Dispatch, SetStateAction } from 'react';
const PathwayAutosuggest: React.FC<{
  setNhi: Dispatch<SetStateAction<string>>;
  nhi: string;
  nhiError: string;
}> = ({ setNhi, nhi, nhiError }) => {
  return (
    <Input
      onChange={({ detail }) => {
        let newNhi = detail.value.toUpperCase();
        if (newNhi.length > 7) {
          newNhi = newNhi.slice(0, 7);
        }
        setNhi(newNhi);
      }}
      value={nhi}
      invalid={nhi !== '' && nhiError !== ''}
      placeholder="Search for NHI"
      autoFocus={true}
    />
  );
};

export default PathwayAutosuggest;
