/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
SPDX-License-Identifier: Apache-2.0
*/
import { ApolloClient, InMemoryCache, gql, ApolloError, ServerError, DefaultOptions } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from '@apollo/client';
import { clearAccessTokenState } from '../utils/AccessTokenUtil';

const GET_PATHWAY_BY_NHI = gql`
  query getPathwayByNHI($nhi: ID!) {
    getPathwayByNHI(nhi: $nhi) {
      diagnoses {
        diagnosis {
          dateOfDiagnosis
          id
          evidence
          evidenceId
          laterality
          bodySite
          histology
          histologyDate
          stages {
            stage
            stageId
            stageDate
            stageGrouping
            n
            nId
            nDate
            m
            mId
            mDate
            t
            tId
            tDate
          }
          diagnosis
        }
        regimens {
          activeCycles
          id
          completedCycles
          cycleCount
          cycles {
            duration
            id
            end
            status
            start
            number
            name
            medicationRequests {
              dose
              id
              name
              status
              timing
            }
            medicationAdministrations {
              dose
              id
              end
              name
              start
              status
              timing
            }
          }
          status
          unknownCycles
          revokedCycles
          start
          onHoldCycles
          name
          lastTreatment {
            dose
            id
            end
            name
            start
            status
            timing
          }
          intent
          futureCycles {
            duration
            end
            medicationAdministrations {
              dose
              end
              name
              start
              status
              timing
            }
            medicationRequests {
              dose
              name
              timing
              status
            }
            name
            number
            status
            start
          }
          end
          ecogStatus
          ecogId
          ecogDate
        }
      }
      patient {
        age
        id
        birthDate
        gender
        nhi
        name {
          given
          family
          otherNames
        }
      }
      linkedNhis
    }
  }
`;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

export default class AppSyncBackend {
  private client: ApolloClient<any>;

  constructor(accessToken: string) {
    const { REACT_APP_APPSYNC_KEY, REACT_APP_APPSYNC_URL } = process.env;

    const httpLink = new HttpLink({
      uri: REACT_APP_APPSYNC_URL
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`
          // 'x-api-key': REACT_APP_APPSYNC_KEY,
        }
      };
    });

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      defaultOptions
    });
  }

  async explore(nhi: string): Promise<{ data: any; error?: any }> {
    try {
      const { data, errors } = await this.client.query({
        query: GET_PATHWAY_BY_NHI,
        variables: { nhi }
      });

      if (errors) {
        return { data: null, error: errors[0] };
      }

      return { data };
    } catch (e) {
      const error = e as ApolloError;

      if ((error.networkError as ServerError)?.statusCode === 401) {
        clearAccessTokenState();
        window.location.reload();
      }

      return { data: null, error };
    }
  }
}
